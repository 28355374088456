import React from "react"

import styles from "./TimeClock.module.scss"

const TimeClock = ({ time, datetime, variant }) => {
  const camelCase = require("camelcase")
  return (
    <div className={`${styles.item} ${styles[camelCase("" + variant)]}`}>
      <img className={styles.icon} src={"/media/svg/time.svg"} alt="" />
      <time dateTime={datetime}>{time}</time>
    </div>
  )
}

TimeClock.defaultProps = {
  variant: "standard",
}

export default TimeClock
