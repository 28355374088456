import { motion, useMotionValue } from "framer-motion"
import { Link } from "gatsby"
import he from "he"
import React, { useRef } from "react"
import Card from "react-bootstrap/Card"

import Image from "@atoms/Image/Image"
import ImageCentered from "@atoms/ImageCentered/ImageCentered"
import Placeholder from "@atoms/Placeholder/Placeholder"
import TimeClock from "@atoms/TimeClock/TimeClock"
import { supify } from "@utils/helpers"

import styles from "./Card.module.scss"

const ModifiedCard = ({
  headingSize,
  type,
  image,
  badges,
  title,
  dateIcon,
  dateTime,
  date,
  imageSize,
  imageAspectRatio,
  imageCentered,
  url,
  linkText,
  lead,
  internalLink,
  zoomImage,
  placeholder,
  target,
}) => {
  const link = useRef()

  // Why proxylink?
  // "It's not disastrous in terms of comprehension, but verbose..."
  // (https://inclusive-components.design/cards/)

  const proxyLink = () => {
    link.current.click()
  }

  const hasLink = url && linkText ? "has-link" : false

  const camelCase = require("camelcase")

  const textFormatted = (lead ? "" + lead : "")
    .split("\n")
    .filter((item) => {
      return item.replace(/(\r\n|\n|\r)/gm, "")
    })
    .map((item, i) => {
      if (item.length > 160) {
        return item.trim() ? (
          <p key={i}>
            {item
              ? he
                  .decode(item.replace(/&nbsp;/g, " "))
                  .substring(0, 160)
                  .concat("…")
              : null}
          </p>
        ) : null
      } else {
        return item.trim() ? (
          <p key={i}>{item ? he.decode(item.replace(/&nbsp;/g, " ")) : null}</p>
        ) : null
      }
    })

  const Heading = headingSize

  const checkVariants = {
    hover: { points: "100,100 100,0 0,100" },
    default: { points: "100,100 100,100 0,100" },
  }

  const [isHovered, setIsHovered] = React.useState(false)
  const points = useMotionValue(0)

  return (
    <Card
      className={`${styles.card} ${styles[camelCase("" + type)]} ${
        styles[camelCase("" + hasLink)]
      }`}
      onClick={proxyLink}
    >
      {hasLink && (
        <motion.div
          className={`${styles.image} ${styles[camelCase("" + imageSize)]}`}
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          initial={"default"}
          animate={isHovered ? "hover" : "default"}
        >
          <div style={{ transform: "scale(" + zoomImage + ")" }}>
            {image && !imageCentered && (
              <Image
                image={image}
                aspectRatio={imageAspectRatio}
                widths={[313, 612, 750, 1080]}
              />
            )}
            {image && imageCentered && (
              <ImageCentered aspectRatio={imageAspectRatio}>
                <Image image={image} widths={[313, 612, 750, 1080]} />
              </ImageCentered>
            )}
            {!image && placeholder && imageAspectRatio && (
              <Placeholder aspectRatio={imageAspectRatio} />
            )}
            <svg preserveAspectRatio="none" viewBox="0 0 100 100">
              <motion.polygon
                variants={checkVariants}
                style={{ points: points }}
              />
            </svg>
          </div>
        </motion.div>
      )}
      {!hasLink && (
        <div className={`${styles.image}`}>
          <div style={{ transform: "scale(" + zoomImage + ")" }}>
            <Image
              aspectRatio={imageAspectRatio}
              image={image}
              widths={[313, 612, 750, 1080]}
            />
          </div>
        </div>
      )}
      <div className={styles.content}>
        {badges && (
          <div className="badge-layout badge-layout-horizontal">{badges}</div>
        )}
        {date && (
          <>
            {!dateIcon && (
              <time className={styles.date} dateTime={dateTime}>
                {date}
              </time>
            )}
            {dateIcon && (
              <TimeClock variant="card" time={date} datetime={dateTime} />
            )}
          </>
        )}
        <Heading>
          <span dangerouslySetInnerHTML={{ __html: supify(title) }}></span>
        </Heading>

        {textFormatted}
        {url && linkText && internalLink && (
          <Link to={url} ref={link}>
            {linkText}
          </Link>
        )}
        {url && linkText && !internalLink && (
          <a
            href={url}
            ref={link}
            target={target}
            rel={target === "_blank" ? "noopener noreferrer" : null}
          >
            {linkText}
          </a>
        )}
      </div>
    </Card>
  )
}

ModifiedCard.defaultProps = {
  zoomImage: 1,
  type: "regular",
  headingSize: "h2",
  imageSize: "regular",
  linkText: "Read more",
  target: "_self",
  image: "",
}

export default ModifiedCard
