import React from "react"

import { SkewBox } from "@atoms/SkewBox/SkewBox"

import styles from "./Stripes.module.scss"

const Stripes = ({ type, color, x, y }) => {
  const camelCase = require("camelcase")
  return (
    <div
      className={`${styles.shape} ${styles[camelCase("" + type)]} ${
        styles[camelCase("" + color)]
      }`}
    >
      <SkewBox x={x} y={y}>
        <div className={styles.container}>
          <svg
            aria-hidden="true"
            preserveAspectRatio="none"
            viewBox="0 0 605.01 339.17"
          >
            <rect
              x="-81.87"
              y="167"
              width="466"
              height="2"
              transform="translate(-79.15 149.4) rotate(-46.19)"
            />
            <rect
              x="-71.87"
              y="167"
              width="466"
              height="2"
              transform="translate(-71.15 169.4) rotate(-46.19)"
            />
            <rect
              x="-46.92"
              y="167"
              width="466"
              height="2"
              transform="translate(-63.72 187.51) rotate(-46.19)"
            />
            <rect
              x="-19.92"
              y="167"
              width="466"
              height="2"
              transform="translate(-55.42 206.99) rotate(-46.19)"
            />
            <rect
              x="3.88"
              y="167"
              width="466"
              height="2"
              transform="translate(-47.97 224.11) rotate(-46.19)"
            />
            <rect
              x="28.83"
              y="167"
              width="466"
              height="2"
              transform="translate(-40.54 242.22) rotate(-46.19)"
            />
            <rect
              x="55.83"
              y="167"
              width="466"
              height="2"
              transform="translate(-32.23 261.71) rotate(-46.19)"
            />
            <rect
              x="80.83"
              y="167"
              width="466"
              height="2"
              transform="translate(-23.98 279.51) rotate(-46.19)"
            />
            <rect
              x="105.78"
              y="167"
              width="466"
              height="2"
              transform="translate(-16.55 297.62) rotate(-46.19)"
            />
            <rect
              x="132.78"
              y="167"
              width="466"
              height="2"
              transform="translate(-8.24 317.1) rotate(-46.19)"
            />
            <rect
              x="156.58"
              y="167"
              width="466"
              height="2"
              transform="translate(-0.8 334.22) rotate(-46.19)"
            />
            <rect
              x="181.53"
              y="167"
              width="466"
              height="2"
              transform="translate(6.63 352.33) rotate(-46.19)"
            />
          </svg>
        </div>
      </SkewBox>
    </div>
  )
}

Stripes.defaultProps = {
  x: 1.2,
  y: 1.2,
}

export default Stripes
