import React from "react"

import styles from "./Placeholder.module.scss"

const Placeholder = ({ aspectRatio }) => {
  const aspectRatioArray = aspectRatio.split(":")

  const paddingBottom =
    aspectRatioArray.length === 2
      ? Math.round((aspectRatioArray[1] / aspectRatioArray[0]) * 100 * 10) /
          10 +
        "%"
      : false

  return aspectRatio && paddingBottom ? (
    <div
      className={styles.responsiveimagecontainer}
      style={{ paddingBottom: paddingBottom }}
    ></div>
  ) : (
    false
  )
}

export default Placeholder
