import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { FadeInUpBox } from "@atoms/FadeInUpBox/FadeInUpBox"
import { IntersectionObserver } from "@atoms/IntersectionObserver/IntersectionObserver"
import { ParallaxBox } from "@atoms/ParallaxBox/ParallaxBox"

import styles from "./CardDuo.module.scss"

const CardDuo = ({ meta, title, primary, secondary }) => {
  return (
    <>
      <section
        className={`${styles.element} base-margin-top-xl base-padding-top base-padding-bottom base-margin-bottom-xl`}
      >
        <Row className="mobile-bottom-margins">
          <Col md={{ span: 6, offset: 1, order: 2 }}>
            <IntersectionObserver>
              <FadeInUpBox>
                <div className={`${styles.header} base-margin-bottom-large`}>
                  <h2>
                    {meta && (
                      <span
                        className={`${styles.meta} base-margin-bottom-small`}
                      >
                        {meta}
                      </span>
                    )}
                    {title}
                  </h2>
                </div>
              </FadeInUpBox>
            </IntersectionObserver>

            <div className={styles.secondary}>{secondary}</div>
          </Col>

          <Col md={{ span: 5, order: 1 }}>
            <ParallaxBox>
              <div className={styles.primary}>{primary}</div>
            </ParallaxBox>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default CardDuo
