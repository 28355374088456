import { useTransform, useViewportScroll } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"

import { MotionBox } from "@atoms/MotionBox/MotionBox"

export const ParallaxBox = ({
  children,
  y = 150,
  easing = "linear",
  //easing = [0.42, 0, 0.58, 1],
  // easing = "easeInOut", // [number, number, number, number] | "linear" | "easeIn" |
  //"easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" |
  //"backInOut" | "anticipate" | EasingFunction;
  ...rest
}) => {
  const { scrollY } = useViewportScroll()
  const ref = useRef()
  const [transformStartValue, setTransformStartValue] = useState(0)
  const [transformLength, setTransformLength] = useState(0)

  useEffect(() => {
    if (!ref.current) return

    const setValues = () => {
      let offsetTop = ref.current.getBoundingClientRect().top
      setTransformStartValue(offsetTop - 568)
    }

    setValues()

    document.addEventListener("load", setValues)
    window.addEventListener("resize", setValues)

    return () => {
      document.removeEventListener("load", setValues)
      window.removeEventListener("resize", setValues)
    }
  }, [ref])

  useEffect(() => {
    setTransformLength(
      window.innerWidth > 767 ? transformStartValue + y + 568 : 0
    )
  }, [transformLength, transformStartValue, y])

  const inputRange = [
    transformStartValue,
    transformStartValue + transformLength,
  ]

  const yRange = [0, y]

  const translateY = useTransform(scrollY, inputRange, yRange, easing)

  return (
    <MotionBox
      className="parallax-box"
      ref={ref}
      initial={{ y: 0, z: 180 }}
      style={transformLength ? { translateY } : {}}
      {...rest}
    >
      {children}
    </MotionBox>
  )
}
