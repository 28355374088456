import React from "react"

import styles from "./ImageCentered.module.scss"

const ImageCentered = ({ aspectRatio, children }) => {
  const aspectRatioArray = aspectRatio?.split(":")

  const paddingBottom =
    aspectRatioArray?.length === 2
      ? Math.round((aspectRatioArray[1] / aspectRatioArray[0]) * 100 * 10) /
          10 +
        "%"
      : false

  return paddingBottom ? (
    <div className={styles.element} style={{ paddingBottom: paddingBottom }}>
      {children}
    </div>
  ) : null
}

ImageCentered.defaultProps = {
  aspectRatio: "1:1",
}

export default ImageCentered
