import { graphql } from "gatsby"
import React from "react"

import Stripes from "@atoms/Stripes/Stripes"
import Card from "@organisms/Card/Card"
import CardDuoMolecule from "@organisms/CardDuo/CardDuo"

const CardDuo = ({ subtitle, title, cards = [] }) => {
  const [primaryCard, secondaryCard] = cards

  let primary = <></>
  let secondary = <></>

  if (primaryCard) {
    primary = (
      <>
        <Stripes type="top-left" color="blue" x="1.4" y="1.4" />
        <Card
          type="medium"
          headingSize="h3"
          title={primaryCard.heading}
          lead={primaryCard.text}
          image={primaryCard.image}
          url={primaryCard.link.uri}
          linkText={primaryCard.link.title}
          internalLink={primaryCard.link.target !== "_blank" ? true : false}
          target={primaryCard.link.target}
        />
      </>
    )
  }

  if (secondaryCard) {
    secondary = (
      <>
        <Stripes type="top-right" color="main-blue" x="1.5" y="1.5" />
        <Card
          type="medium"
          headingSize="h3"
          title={secondaryCard.heading}
          lead={secondaryCard.text}
          image={secondaryCard.image}
          url={secondaryCard.link.uri}
          linkText={secondaryCard.link.title}
          internalLink={secondaryCard.link.target !== "_blank" ? true : false}
          target={secondaryCard.link.target}
        />
      </>
    )
  }

  return (
    <CardDuoMolecule
      meta={subtitle}
      title={title}
      primary={primary}
      secondary={secondary}
    />
  )
}

export const query = graphql`
  fragment CardDuo on WpCardDuo {
    __typename
    subtitle
    title
    cards {
      heading
      text
      image {
        altText
        sourceUrl: sourceUrlLarge
        mediaDetails: mediaDetailsLarge {
          width
          height
        }
        dominantColor
        hasTransparency
      }
      link {
        target
        title
        url
        uri
      }
    }
  }
`

export default CardDuo
