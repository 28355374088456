export const campusMapper = (campus) => {
  const table = {
    Asemakeskus: "keskusta",
    Aviapolis: "Aviapolis",
    Fornebu: "Fornebu",
    Gasperich: "Cloche d’Or",
    Hermia: "Hervanta",
    Kista: "Kista",
    Kontinkangas: "Kontinkangas",
    Linnanmaa: "Linnanmaa",
    Otaniemi: "Otaniemi",
    Peltola: "Peltola",
    Ruoholahti: "Ruoholahti",
    Ullevi: "Gårda",
    Ydinkeskusta: "keskusta",
    Yliopistonrinne: "keskusta",
  }

  return table[campus] || campus
}

export const isOffice = (title) => {
  const table = ["Office", "Toimisto", "Kontor", "Bureau"]

  return table.some((v) => title.includes(v))
}

export const supify = (string) => {
  return string?.replace("m2", "m<sup>2</sup>")
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
